import React from 'react';
import Window from './window'
import Layout from './layout'
import { withRouter } from "react-router-dom";
import { isSmall } from '../utility'
import '../css/dashboard.css'
import '../css/animation.css';
import '../css/font-size.css';
import '../css/optimiseScreen.css';
import '../css/setGridView.css';


const doChange = (className, setclassName, isHome) => {
  if (className !== null && isSmall) return;
  let final = ""
  if (isHome) final = " no-window "

  let run = (cb) => cb();
  if (className !== null) {
    run = (cb) => {
      setclassName("do-exit " + className);
      setTimeout(() => {
        setclassName("do-exit animate-window " + className);
        setTimeout(() => cb(), 200);
      }, 400);
    }
  }

  run(() => {
    setclassName("do-entry" + final);
    setTimeout(() => setclassName(final), 1000);
  })
}

const Dashboard = ({ location }) => {
  const [className, setclassName] = React.useState(null);
  const temp = location.pathname === '/'
  React.useEffect(() => {
    doChange(className, setclassName, temp)
    // eslint-disable-next-line
  }, [temp])
  if (className == null) return <div />
  return (
    <div className={"container shadow " + (className)}>
      <div className={"wrapper shadow " + (className)}>
        <Layout className={className} />
        <div className="window-wrapper">
          <Window redirect={() => { }} />
        </div>
      </div>
    </div>
  )

}

export default withRouter(props => <Dashboard {...props} />);
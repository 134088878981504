import { FaPython, FaReact, FaFlask, FaNodeJs, FaGit, FaDocker } from "react-icons/fa"
import { DiJavascript, DiHaskell, DiMysql, DiMongodb } from "react-icons/di"
import { SiCassandra, SiNginx } from "react-icons/si"
import { GrHadoop } from "react-icons/gr"
import { AiFillCopyrightCircle } from "react-icons/ai"
const skills = {
    Languages: [["C++", AiFillCopyrightCircle], ["Python", FaPython], ["JavaScript", DiJavascript], ["Haskell", DiHaskell]],
    Frameworks: [
        ["React", FaReact], ["React-Native", FaReact], ["Flask", FaFlask], ["Express.js", FaNodeJs]
    ],
    Databases: [
        ["SQL", DiMysql], ["MongoDB", DiMongodb], ["Cassandra", SiCassandra]
    ],
    Technology: [
        ["Docker", FaDocker], ["Git/Github", FaGit], ["Hadoop", GrHadoop], ["Nginx", SiNginx]
    ]
}

export default skills
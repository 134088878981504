import crPs from '../resources/certificates/cr_ps.jpg'
import crNode from '../resources/certificates/cr_node.jpg'
import crHadoop from '../resources/certificates/cr_hadoop.jpg'
import crDBMS from '../resources/certificates/cr_dbms.jpg'

const certificates = [
    {
        title: "Problem Solving (Advanced) Certificate", 
        platform: "Hackerrank", img: crPs, 
        link: "https://www.hackerrank.com/certificates/28be45f17132"
    },
    {
        title: "Database Management Essentials", 
        platform: "Coursera", img: crDBMS, 
        link: "https://www.coursera.org/account/accomplishments/verify/C9J3DT39NJP2"
    },
    {
        title: "Hadoop Platform and Application Framework", 
        platform: "Coursera", img: crHadoop, 
        link: "https://www.coursera.org/account/accomplishments/verify/HD3K2R8S5DY8"
    },
    {
        title: "Node with React: Fullstack Web Development", 
        platform: "Udemy", img: crNode, 
        link: "https://www.udemy.com/certificate/UC-7ZVLCM0K/"
    },

]

export default certificates
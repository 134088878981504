import React, { useEffect } from 'react'
import { Button, Box } from 'react-desktop/macOs';
import "../../css/openSource.css";
import gStat2019 from "../../resources/openSource/g-stat-2018-w.png"
import { AiOutlineArrowRight } from "react-icons/ai";
import data from '../../dataModel/openSource'

const BannerItem = ({ item }) => (
  <a className="no-decoration" href={item.link} target="_blank" rel="noreferrer" >
    <li>
      <div className={"icon type " + item.status}>
        <item.Icon />
      </div>
      <div>
        <h3>{item.head}</h3>
        <p>{item.body}</p>
      </div>
      <div className="icon link">
        <AiOutlineArrowRight />
      </div>
    </li>
  </a>
)

const Org = ({ org }) => (
  <div className="org">
    <div className="img"><img src={org.img} alt={org.name} /></div>
    <h3>{org.name}</h3>
    <div className="stats">
      <p>
        <span className="pos">+{org.plus}</span>
                      &nbsp;|&nbsp;
                    <span className="neg">-{org.minus}</span>
      </p>
      <p>{org.commits} Commits</p>
      <p>{org.pr} Pull Requests</p>
      <p>{org.issues ? (org.issues + " Issues") : "-"} </p>
      <a className="no-decoration" href={org.link} target="_blank" rel="noreferrer"  >
        <Button color="blue" style={{ margin: 10 }}>
          {"Visit"}
        </Button>
      </a>
    </div>
  </div>
)

const OP = () => {
  useEffect(() => {
    document.title = `Open Source Profile | Shubham Awasthi`
  }, [])
  return (
    <div className="open-source-wrapper animateWrapper">
      <Box className="github-wrapper">
        <div className="github-profile">
          <div>
            <img src={data.github.img} alt={data.github.title} />
          </div>
          <div className="github-cnt">
            <h2>{data.github.title}</h2>
            <p>awasthishubh</p>
            {(() => {
              return data.github.lines.map((line) => <p key={line} style={{ whiteSpace: "nowrap" }}>{line}</p>)
            })()}
            <p><a className="link" href={data.github.link} target="_blank" rel="noreferrer">
              {data.github.link}
            </a></p>
            <div className="table">
            </div>
          </div>
        </div>
        <div className="github-stats middle">
          <img src={gStat2019} alt="github-stats" />
        </div>

      </Box>
      <Box >
        <div className="gsoc">
          <div className="gsoc-img">
            <img src={data.gsoc.img} alt={data.gsoc.title} />
          </div>
          <div className="gsoc-cnt">
            <h2>{data.gsoc.title}</h2>
            <h3>{data.gsoc.subTitle}</h3>
            <p>{data.gsoc.body}</p>
            <p>
              {(() => {
                return data.gsoc.buttons.map((btn) => (
                  <a key={btn.link} className="no-decoration" href={btn.link} target="_blank" rel="noreferrer"  >
                    <Button color="blue" style={{ margin: 10 }}>
                      {btn.title}
                    </Button>
                  </a>
                ))
              })()}

            </p>
          </div>
        </div>

      </Box>
      <Box>
        <div className="contribtions">
          <h2>Top Organisation Contributed</h2>
          <div>
            <div className="orgs">
              {(() => {
                return data.topOrganisation.map((org, ind) => <Org key={ind} org={org} />)
              })()}
            </div>
          </div>
        </div>
      </Box>

      <Box className="banner">
        <h2>Top Pull Requests</h2>
        <ul style={{ padding: "inherit" }}>
          {(() => {
            return data.topPrs.map((item, ind) => <BannerItem key={ind} item={item} />)
          })()}
        </ul>
      </Box>

      <Box className="banner">
        <h2>Top Issues Reported</h2>
        <ul style={{ padding: "inherit" }}>
          {(() => {
            return data.topIssues.map((item, ind) => <BannerItem key={ind} item={item} />)
          })()}

        </ul>
      </Box>

    </div>
  )
}

export default OP

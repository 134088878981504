import {
    Me, Education, Experience, Projects, Skills,
    Contact, CodingProfiles, OpenSourceProfile, Certificates, Achievements, Extracurricular
} from '../components/views'
import { RiHandHeartLine, RiTodoLine } from 'react-icons/ri'
import { GiArtificialIntelligence } from 'react-icons/gi'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import { BiCodeCurly, BiMedal } from 'react-icons/bi'
import { BsCodeSlash, BsDownload } from 'react-icons/bs'
import { FiSend, FiLinkedin, FiGithub, FiMail, FiTwitter } from 'react-icons/fi'
import { isSmall } from "../utility";
import { GrUserWorker } from 'react-icons/gr'
import { IoSchoolOutline } from 'react-icons/io5'
import { VscOrganization } from 'react-icons/vsc'
const layout = {
    prefex: "explore",
    home: isSmall ? -1 : 0,
    smallTiles: [
        { id: "ab", text: "Me",                 link: "me",                 title: "Me",                    icon: GrUserWorker, view: Me },
        { id: "pr", text: "Projects",           link: "projects",           title: "Projects",              icon: RiTodoLine, view: Projects },
        { id: "sk", text: "Skills",             link: "skills",             title: "Skills",                icon: GiArtificialIntelligence, view: Skills },
        { id: "ed", text: "Education",          link: "education",          title: "Education",             icon: IoSchoolOutline, view: Education },
        { id: "ex", text: "Work Experience",    link: "experience",         title: "Experience",            icon: VscOrganization,  view: Experience },
        { id: "ac", text: "Achievements",       link: "achievements",       title: "Achievements",          icon: BiMedal, view: Achievements },
        { id: "cr", text: "Certificates",       link: "certificates",       title: "Certificates",          icon: AiOutlineSafetyCertificate,  view: Certificates },
        { id: "cp", text: "Coding Profiles",    link: "coding-profile",    title: "Coding Profile",         icon: BiCodeCurly, view: CodingProfiles },
        { id: "op", text: "Open Source Profile",link: "open-source-profile",title: "Open Source Profile",   icon: BsCodeSlash, view: OpenSourceProfile },
        { id: "ec", text: "Extracurricular",    link: "extracurricular",    title: "Extracurricular",       icon: RiHandHeartLine, view: Extracurricular },
        { id: "co", text: "Contact",            link: "contact",            title: "Contact Me",            icon: FiSend, view: Contact },
    ],
    socialTiles: {
        id: 'so',
        tiles: [
            { text: "Linkedin", Icon: FiLinkedin, id: "li", link: "https://www.linkedin.com/in/awasthishubh/", },
            { text: "Github", Icon: FiGithub, id: "gi", link: "https://github.com/awasthishubh", },
            { text: "Mail", Icon: FiMail, id: "gi", link: "maito:aw.shubh@gmail.com", },
            { text: "Twitter", Icon: FiTwitter, id: "gi", link: "https://twitter.com/aw_shubh", },
        ],
    },
    linkTiles: [
        { text: "Resume", icon: BsDownload, id: "re", link: "https://awasthishubh.github.io/resources/Shubham_SDE_resume.pdf", },
    ],
    headTile: {
        id: "hd",
        title: "Shubham Awasthi",
        subTitle: "Final Year Student"
    }
}


export default layout
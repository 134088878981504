import haskellImg from '../resources/openSource/haskell.png'
import biojsImg from '../resources/openSource/biojs.png'
import zulipImg from '../resources/openSource/zulip.png'
import intermineImg from '../resources/openSource/intermine.png'
import gscoImg from '../resources/openSource/gsoc.png'
import githubImg from '../resources/openSource/github.png';
import { GoIssueClosed, GoIssueOpened, GoGitMerge } from "react-icons/go";

const data = {
    github: {
        link: "https://github.com/awasthishubh",
        img: githubImg,
        title: "GitHub",
        lines:[
            "Arctic Code Vault Contributor",
            "Contributed to 10+ Organisations",
        ]
    },
    gsoc: {
        img : gscoImg,
        title: "Google Summer of Code 2020",
        subTitle: "with Haskell.org",
        body: "Successfully completed",
        buttons: [
            {title: "Code", link: "https://github.com/haskell/hackage-server/pull/892/commits"},
            {title: "Work Report", link: "https://awasthishubh.github.io/gsoc-2020-haskell/report.html"},
        ]
    },
    topOrganisation: [
        {
            name: "Haskell.org",
            commits: 32,
            plus: 1151,
            minus: 558,
            pr: 6,
            issues: 3,
            img: haskellImg,
            link: "http://github.com/haskell/"
        },
        {
            name: "Biojs",
            commits: 5+1,
            plus: 33+17,
            minus: 13+3,
            pr: 5,
            issues: 3,
            img: biojsImg,
            link: "https://github.com/biojs/"
        },
        {
            name: "Zulip",
            commits: 1,
            plus: 5,
            minus: 5,
            pr: 2,
            issues: 1,
            img: zulipImg,
            link: "https://github.com/zulip/"
        },
        {
            name: "Intermine",
            commits: 1,
            plus: 14,
            minus: 8,
            pr: 1,
            issues: null,
            img: intermineImg,
            link: "https://github.com/intermine"
        },
        
    ],
    topPrs: [
        {
            id: 1,
            head: "Use npm api to get weekly dowloads count (#50)",
            body: "#51 by awasthishubh was merged on 24 Apr 2019.",
            status: "merged",
            link: "https://github.com/intermine",
            Icon: GoGitMerge
        },
        {
            id: 2,
            head: "Fix: Accept space seperated flags in Build Report",
            body: "#889 by awasthishubh was merged on 9 Jul 2020.",
            status: "merged",
            link: "https://github.com/intermine",
            Icon: GoGitMerge
        },
        {
            id: 3,
            head: "Updated HsLua version in Stack.yaml )",
            body: "#1 by awasthishubh was merged on 7 Feb 2020.",
            status: "merged",
            link: "https://github.com/intermine",
            Icon: GoGitMerge
        },
        
    ],
    topIssues: [
        {
            id: 1,
            head: "First attempt to open camera/gallery fails ",
            body: "#3787 opened on 10 Jan 2020 by awasthishubh",
            status: "open",
            link: "https://github.com/intermine",
            Icon: GoIssueOpened
        },
        {
            id: 2,
            head: "Flag --test-keep-tix-files doesn't work with v2-test",
            body: "#6938 opened on 4 Jul 2020 by awasthishubh",
            status: "open",
            link: "https://github.com/intermine",
            Icon: GoIssueOpened
        },
        {
            id: 3,
            head: "Invalid use of MirrorClient Cache",
            body: "#874 by awasthishubh was closed on 3 Jun 2020",
            status: "closed",
            link: "https://github.com/intermine",
            Icon: GoIssueClosed
        },
        
    ]
}

export default data




/*

haskell
    32 commits    1,151 ++    558 --
    6 PR 3 issues

Zulip
    1 commit    5 ++    5 --
    2PR 1 issue

Intermine Bluegenes
    1 commit    14 ++    8 --
    1PR

biojs
5 commits    33 ++    13 --
1 commit    17 ++    3 --
5 PR 3 issues

Significant
https://github.com/zulip/zulip-mobile/issues/3787
https://github.com/haskell/cabal/issues/6938
https://github.com/haskell/hackage-server/issues/874

https://github.com/hslua/hslua-examples/pull/1
https://github.com/biojs/biojs-backend/pull/51
https://github.com/haskell/hackage-server/pull/889




https://github.com/haskell/hackage-server/issues?q=author%3Aawasthishubh+
https://github.com/haskell/cabal/issues?q=author%3Aawasthishubh+
https://github.com/zulip/zulip-mobile/graphs/contributors
https://github.com/intermine/bluegenes/graphs/contributors
https://github.com/biojs/biojs-frontend/issues?q=author%3Aawasthishubh+
https://github.com/biojs/biojs-backend/issues?q=author%3Aawasthishubh+
https://github.com/biojs/biojs.github.io

*/
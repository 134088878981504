// import React from 'react'
const projects = [
    {
        sno: 1,
        projectName: "CampDiaries Vol. Management",
        startDate: "06/2020",
        endDate: "07/2020",
        info: [
            "The JP Morgan Chase and Co. internship Project.",
            "A system based on the requirements of an NGO Camp Diaries.",
            "Involved analysis, development and testing",
            "Aims to provide various features with flexible access control."
        ],
        tech: [
            "Javascript", "Python", "Angular", "Flask","MongoDB", "AWS", "Nginx"
        ],
        links: [
            // { title: "Source Code", link: "https://github.com/awasthishubh/luaHttp" },
        ],
    },
    {
        sno: 4,
        projectName: "FreeSlot",
        startDate: "06/2018",
        endDate: "05/2019",
        info: [
            "A timetable management system built for Clubs/Chapters",
            "It uses image processing to extract information from a Timetable Screenshot & provide a free student at the given time.",
            "An additional Chrome Extension to extract the students timetable from VTOP (Online portal for VIT Students)",
        ],
        links: [
            { title: "Website", link: "http://freeslot.acmvit.in/" },
        ],
        tech: [
            "Javascript", "Python", "Image Processing", "React", "Redux", "MongoDB","Flask",
        ],
    },
    {
        sno: 2,
        projectName: "LuaHTTP: A Lua interface to http-client",
        startDate: "03/2020",
        endDate: "03/2020",
        info: [
            "A minimalistic http client which uses Haskell and HsLua",
            "Powers Lua, a lightweight scripting language, to make HTTP/S requests."
        ],
        links: [
            { title: "Source Code", link: "https://github.com/awasthishubh/luaHttp" },
        ],
        tech: [
            "Haskell", "Lua", "Http", "request"
        ],
    },
    {
        sno: 3,
        projectName: "Location-based Attendance App",
        startDate: "01/2019",
        endDate: "11/2019",
        info: [
            "Creates a lobby and lets the nearby devices join.",
            "Based on the threshold distance, specified by the admin, it marks the attendance of attendees"
        ],
        links: [
            { title: "Expo", link: "https://expo.io/@awasthishubh/attendance-app" },
        ],
        tech: [
            "React-Native", "Redux", "Native App", "GPS", "Socket.io"
        ],
    },
    {
        sno: 5,
        projectName: "VPN Proxy Server",
        startDate: "03/2019",
        endDate: null,
        info: [
            "Creates a proxy server using a cloud virtual machine.",
            "Allows access to content that is blocked by our location from some other location where the server is hosted."
        ],
        links: [
            { title: "Source Code", link: "https://github.com/awasthishubh/vpn-server" },
        ],
        tech: [
            "Cloud", "VPN", "Node"
        ],
    },
    {
        sno: 6,
        projectName: "WatchPush",
        startDate: "12/2018",
        endDate: null,
        info: [
            "NPM Package that eases the development on IaaS platform.",
            "Uses GitHub webhooks to automatically rebuild and restart applications as you push changes to your GitHub repository."
        ],
        links: [
            { title: "Source Code", link: "https://www.npmjs.com/package/watchpush" },
        ],
        tech: [
            "Node", "NPM", "Cloud"
        ],
    },
    {
        sno: 7,
        projectName: "ACM Codart, Reverse Coding Portal",
        startDate: "02/2019",
        endDate: "10/2018",
        info: [
            "A portal to provide code submission and evaluation against test cases with the help of docker containers."
        ],
        links: [
            { title: "Source Code", link: "https://github.com/awasthishubh/codart18" },
        ],
        tech: [
            "Docker", "Javascript", "MongoDB", "React"
        ],
    },
    {
        sno: 8,
        projectName: "Easeoauth",
        startDate: "06/2018",
        endDate: "03/2020",
        info: [
            "NPM Package to ease the OAuth authentication process of various OAuth providers with just a few lines of codes."
        ],
        links: [
            { title: "Source Code", link: "https://www.npmjs.com/package/easeoauth" },
        ],
        tech: [
            "NPM", "Node", "Oauth"
        ],
    },
    {
        sno: 9,
        projectName: "WebCanvass",
        startDate: "12/2017",
        info: [
            "A Web Application where people can post issues and their topics of interest and have a discussion on it."
        ],
        links: [
            { title: "Source Code", link: "https://github.com/awasthishubh/webcanvass" },
        ],
        tech: [
            "PHP", "CodeIgniter", "HTML/CSS/JS"
        ],
    }
]

export default projects
import rlbImg from '../resources/education/rlb.png'
import vitImg from '../resources/education/vit.jpg'
import React from 'react'
import {FaSchool} from 'react-icons/fa'
import {GiSchoolBag} from 'react-icons/gi'
import {IoSchoolSharp} from 'react-icons/io5'
const experiences = [
    {
        name: "Bachelor of Technology",
        school: "Vellore Institute of Technolofy, Vellore",
        year: "2017-2021",
        score: "9.05 CGPA",
        stream: "Information Technology",
        icon: <IoSchoolSharp />,
        img: vitImg
    },
    {
        name: "Intermediate",
        school: "Rani Laxmi Bai Memorial Schools, Lucknow",
        year: "2017",
        score: "89.8%",
        stream: "PCM with Computer Science",
        icon: <FaSchool />,
        img: rlbImg
    },
    {
        name: "High School",
        school: "Rani Laxmi Bai Memorial Schools, Lucknow",
        year: "2015",
        score: "9.4 CGPA",
        // stream: "Science, Maths, ...",
        icon: <GiSchoolBag />,
        img: rlbImg
    },
]

export default experiences
import React from 'react';
import { Link } from 'react-router-dom';
import { getDelay } from '../utility'
import dp from '../resources/dp.svg'

const Tile = ({ Icon, text, active, className, id, link }) => {
  return (
    <Link to={link} style={{ gridArea: id, animationDelay: getDelay() }} className={"tile hvr-grow shadow" + (active ? " active" : "")}>
      <div className="tile-icon"><Icon /></div>
      <div>{text}</div>
    </Link>
  )
}
const LinkTile = ({ id, link, Icon, text, name }) => {
  return (
    <a className=" middle tile hvr-grow shadow" href={link} target="_blank" rel="noreferrer" aria-label={name || text}
      style={{ gridArea: id, animationDelay: getDelay() }} >
      <div className="tile-icon"><Icon /></div>
      {text && (<div>{text}</div>)}
    </a>
  )
}

const SocialTiles = ({ id, tiles }) => {
  return (
    <div className="social-wrapper" style={{ gridArea: id }}>
      {(() => {
        return tiles.map(({ Icon, link, text }) => {
          return <LinkTile key={link} Icon={Icon} link={link} name={text} />
        })
      })()}
    </div>
  )
};


const HeadTile = ({ id, title, subTitle, link }) => {
  return (
    <Link to={link} className={"tile shadow no-link"} style={{ justifyContent: "space-evenly", textDecoration: "none", gridArea: id, animationDelay: getDelay() }}>
      <div><h2>{title}</h2></div>
      <div>{subTitle}</div>
    </Link>
  )
};

const ImgTile = () => {
  return (
    <div id={"tiad"} className={"tile shadow img-tile show-on-full no-link"}
      style={{ textDecoration: "none", gridArea: "im", animationDelay: getDelay() }}>
      {/* <div> */}
        <img className="hipper" src={dp} alt={'Shubham Awasthi'} />
      {/* </div> */}
      {/* <img className="show-on-no-wrapper" src={dp} alt={'Shubham Awasthi'} /> */}

    </div>
  )
};

export {
  Tile, HeadTile, SocialTiles, LinkTile, ImgTile
}
import React from 'react';
import { HeadTile, LinkTile, Tile, SocialTiles, ImgTile } from './tiles'
import { withRouter } from "react-router-dom";
import '../css/layout.css';
import data from "../dataModel/layout";
import { getLink } from '../utility'

const Layout = ({ location, className }) => {
  const { smallTiles, linkTiles, headTile } = data

  return (
    <div className={"grid-wrapper " + className} style={{ height: "100%", gridTemplateAreas: data.gridTemplateAreas }} >
      <HeadTile
        link={`/`}
        id={headTile.id}
        title={headTile.title} subTitle={headTile.subTitle} />
      <ImgTile />
      {(() => smallTiles.map(({ text, icon, id, link }) =>
        <Tile
          key={id}
          id={id}
          text={text}
          Icon={icon}
          link={getLink(link)}
          active={location.pathname === getLink(link)} />
      )
      )()}
      {(() => linkTiles.map(({ text, icon, id, link }) =>
        <LinkTile key={id} id={id} text={text} Icon={icon} link={link} />
      )
      )()}
      <SocialTiles key={data.socialTiles.id} id={data.socialTiles.id} tiles={data.socialTiles.tiles} />
    </div>
  )
}

export default withRouter(props => <Layout {...props} />);

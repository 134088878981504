import c2c from '../resources/extracurricular/c2c.jpg'
import codart from '../resources/extracurricular/codart.jpg'
import cyd from '../resources/extracurricular/cyd.jpg'
import lbh from '../resources/extracurricular/lbh.jpeg'
import pyflask from '../resources/extracurricular/pyflask.jpg'
import rc from '../resources/extracurricular/rc.jpeg'
import weekenlabs from '../resources/extracurricular/weekendlabs.jpeg'

import vitHack from '../resources/achievements/vit_hack.jpg'
import bcm from '../resources/achievements/bcm.png'
import zeit from '../resources/achievements/zeit.jpg'
import infytq from '../resources/achievements/infytq.jpg'

const photoGrid = {
    achievements: [
        { img: vitHack, title: "VIT Hack Winner", content: "Won First Place and a cash prize of ₹1Lakh at VIT Hackathon 2019 under Mobility Track" },
        { 
            img: zeit, 
            title: "Zeit Challenge", content: "Won a prize of $300 at Zeit challenge presented by Vercel"
        },
        {
            img: bcm,
            title: "BeChangeMaker 2018", content: "Selected as the top 9th team of BeChangeMaker 2018"
        },
        {
            img: infytq,
            title: "Infosys Certification", content: "Certification exam organised by Infosys to be the Infosys Certified Developer."
        }

    ],
    extracurricular: [
        {   img: cyd, title: "Code Your Dreams", 
            content: "A session was organised in an local government school where we taught the children about the basics of computers and motivated them towards the concept of coding. " },
        {   img: weekenlabs, title: "Weeken Labs", 
            content: "An initiative to teach topics uncovered academically to fellow students on weekends" },
        {   img: lbh, title: "Local Hack Day Build", 
            content: "A one day hack to give little teaser to freshers into the world of competitive creation." },
        {   img: c2c, title: "Code 2 Create", 
            content: "Our team organized the hackathon: Code2Create one of the flagship event of VIT Vellore" },
        {   img: rc, title: "Reverse Coding", 
            content: "Organized" },
        {   img: pyflask, title: "PyFlask", 
            content: "Organized" },
        {   img: codart, title: "Codart", 
            content: "Organized" },
    ]
}

export default photoGrid